import * as React from 'react'
import Menu from '../menu/menu'

import * as styles from './header.module.scss'

const Header = () => (
  <header className={styles.header}>
    <Menu />
  </header>
)

export default Header
