import * as React from 'react'
import { Link } from 'gatsby'
import {
  faChevronCircleUp, faChevronCircleDown
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as styles from './menu.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuIsVisible: false,
      menuIcon: faChevronCircleDown
    }

    this.showContactsContainer = this.showContactsContainer.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  showContactsContainer(event) {
    event.preventDefault()
    document.getElementById('contacts-form-block').style.display = 'block'
  }

  componentDidMount() {
    // Nothing here
  }

  componentWillUnmount() {
    // Nothing here
  }

  toggleMenu() {
    this.setState(state => ({
      menuIsVisible: !state.menuIsVisible
    }))
  }

  render () {
    return (
      <navbar className={styles.menu_block}>

        <div>
          <div>
            <header>
              <Link to="/">
                <StaticImage
                  src='../../images/westbournco-logo-small.png'
                  alt=''
                  placeholder='blurred'
                  width={48}
                />
              </Link>
            </header>

            <header>
              <Link to="/" className={styles.title_link}>Westbourn</Link>
            </header>

            <menu className={styles.menu__desktop}>
              <ul>
                <li><Link to="/#about">About</Link></li>
                <li><Link to="/#services">Services</Link></li>
                <li><Link to="/#contacts">Contacts</Link></li>
              </ul>
            </menu>
            <menu className={styles.menu__mobile}>
              <div className={( this.state.menuIsVisible ? styles.visible : styles.hidden )}>
                <button onClick={this.toggleMenu}>
                  <FontAwesomeIcon
                    icon={( this.state.menuIsVisible ? faChevronCircleUp : faChevronCircleDown )}
                    className="icon-class"
                  />
                </button>
              </div>
            </menu>

            <section>
              <a href="/#" onClick={this.showContactsContainer}>Contact Us</a>
            </section>
          </div>
        </div>

        <menu className={( this.state.menuIsVisible ? styles.visible : styles.hidden )}>
          <ul className={( this.state.menuIsVisible ? styles.visible : styles.hidden )}>
            <li><Link to="/#about">About</Link></li>
            <li><Link to="/#services">Services</Link></li>
            <li><Link to="/#contacts">Contacts</Link></li>
          </ul>
        </menu>

      </navbar>
    )
  }
}

export default Menu
