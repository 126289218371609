import * as React from "react"
import PropTypes from "prop-types"

import Header from "../header/header"
import Footer from "../footer/footer"

import * as styles from "./layout.module.scss"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className={styles.container}>
        {children}
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
