import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import * as styles from './footer.module.scss'


class Footer extends React.Component {
  constructor(props) {
    super(props)

    this.showContactsContainer = this.showContactsContainer.bind(this)
  }

  //
  // showContactsContainer() ...
  // Resets error state to a no error state
  //
  showContactsContainer(event) {
    event.preventDefault()
    document.getElementById('contacts-form-block').style.display = 'block'
  }

  //
  // render() ...
  // Renders component depending on a current state
  //
  render() {

    return (
      <footer id='contacts' className={styles.footer}>
        <div className={styles.footer_contacts}>
          <div>
            <StaticImage
              src='../../images/westbournco-logo.png'
              alt=''
              placeholder='blurred'
              width={150}
            />
          </div>
          <div>
            <h4>Links</h4>
            <ul>
              <li><a href='/#about'>About</a></li>
              <li><a href='/#services'>Services</a></li>
              <li><a href='/#contacts'>Contacts</a></li>
            </ul>
          </div>
          <div>
            <h4>Contacts</h4>
            <ul>
              <li>
                <a
                  href="/#"
                  onClick={this.showContactsContainer}
                  className={`${styles.button} ${styles.button__blue}`}>
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.footer_copyrights}>
          <small>All rights reserved. &copy; {new Date().getFullYear()}</small>
        </div>
      </footer>
    )
  }
}

export default Footer
